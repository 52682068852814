<template>
	<div>
		<ktv-dialog
			v-model="is.showing.deleteDialog"
			:text="$t('Do you want to delete this data?', 'Do you want to delete this data?')"
			:title="$t('Delete Data', 'Delete Data')"
		>
			<template #footer>
				<ktv-button :text="$('Delete', 'Delete')" :loading="is.loading" @click="deleteData()" />
				<ktv-button :text="$('Cancel', 'Cancel')" color="light" :loading="is.loading" class="ml-2" @click="closeDeleteDialog()" />
			</template>
		</ktv-dialog>
		<basic-data-product-update-modal :product="product" :show="is.showing.updateModal" @close="closeUpdateModal" @on-save="updateProduct" />
		<ktv-form :label="$t('AO Package Setting Basic Data Add', 'AO Package Setting Basic Data Add')" @submit="save">
			<template #content="{ invalid }">
				<basic-data-product-add-modal
					v-if="localPackage.district"
					:show="is.showing.addModal"
					:district-id="localPackage.district"
					@close="closeAddModal"
					@on-save="addProduct"
				/>
				<b-row>
					<ktv-select v-model="localPackage.district" :options="districtOptions" rules="required" :label="$t('District', 'District')" />
				</b-row>
				<b-row>
					<ktv-select v-model="localPackage.aoNumber" :options="aoNumberOptions" rules="required" :label="$t('AO Number', 'AO Number')" />
				</b-row>
				<b-card
					:header="$t('Product List', 'Product List')"
					align="left"
					border-variant="primary"
					class="mt-4 mb-2"
					header-bg-variant="primary"
					header-text-variant="white"
					no-body
				/>
				<ktv-table
					id="basic-data"
					:columns="columns"
					:filter="false"
					:is-loading="is.loading"
					:rows="filteredData"
					:search-enabled="false"
					:search-on-enter="false"
					:search-placeholder="$t('Search by product name or id', 'Search by product name or id')"
					:total-rows="addProductAOPackageSettingList ? filteredData.length : 0"
					min-height="250px"
					mode="client"
					@on-search="onSearch"
				>
					<template #actionleft>
						<ktv-button :text="$t('Add', 'Add')" icon="add" @click="showAddModal" />
					</template>
					<template #columns="{ props }">
						{{ $t(props.column.label, props.column.label) }}
					</template>
					<template #rows="{ props }">
						<span v-if="props.column.field === 'button'">
							<b-dropdown size="xs" variant="link" toggle-class="text-decoration-none p-0" no-caret>
								<template #button-content>
									<img :src="icons.dropdown" />
								</template>
								<b-dropdown-item @click="showUpdateModal(props.row)">
									<img :src="icons.edit" class="imgicon" />{{ $t("Update", "Update") }}
								</b-dropdown-item>
								<b-dropdown-item @click="showDeleteDialog(props.row)">
									<img :src="icons.delete" class="imgicon" />{{ $t("Delete", "Delete") }}
								</b-dropdown-item>
							</b-dropdown>
						</span>
						<span v-else-if="props.column.field === 'salePrice'">{{ props.row.salePrice | Rupiah }}</span>
					</template>
				</ktv-table>
				<div class="bg-gray-100 p-4 mt-4" align="center">
					<ktv-button
						:disabled="invalid || addProductAOPackageSettingList.length <= 0"
						:loading="is.loading"
						:text="$t('Save', 'Save')"
						icon="save"
						type="submit"
					/>
				</div>
			</template>
		</ktv-form>
	</div>
</template>

<script>
	import { KtvButton, KtvDialog, KtvForm, KtvSelect, KtvTable } from "@/components"
	import { each, filter, map, reject } from "underscore"
	import { mapActions, mapState } from "vuex"

	import BasicDataProductAddModal from "./modals/_BasicDataProductAdd"
	import BasicDataProductUpdateModal from "./modals/_BasicDataProductUpdate"

	import deleteIcon from "@/assets/images/icon/icondelete.png"
	import dropdownIcon from "@/assets/images/icon/icondropdown.png"
	import editIcon from "@/assets/images/icon/iconedit.png"

	const icons = {
		delete: deleteIcon,
		dropdown: dropdownIcon,
		edit: editIcon,
	}

	export default {
		name: "PremiumPointsAOPackageAddBasicData",
		metaInfo: {
			title: "Package Add - Basic Data",
		},
		components: { BasicDataProductAddModal, BasicDataProductUpdateModal, KtvButton, KtvDialog, KtvForm, KtvSelect, KtvTable },
		data() {
			return {
				aoNumberOptions: [],
				columns: [
					{
						field: "button",
						html: true,
						sortable: false,
						tdClass: "text-center",
						thClass: "text-center",
						width: "50px",
					},
					{
						field: "premiumProductId",
						label: "ID",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "premiumName",
						label: "Product Name",
						thClass: "text-left",
					},
					{
						field: "productUnitName",
						label: "Unit Type",
						tdClass: "text-center",
						thClass: "text-center",
						width: "150px",
					},
					{
						field: "salePrice",
						label: "Price (/unit)",
						tdClass: "text-right",
						thClass: "text-right",
						width: "200px",
					},
					{
						field: "priority",
						label: "Priority",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
				],
				deleteProductId: null,
				districtOptions: [],
				icons: icons,
				is: {
					loading: false,
					showing: {
						addModal: false,
						deleteDialog: false,
						updateModal: false,
					},
				},
				localPackage: {
					district: null,
					aoNumber: null,
				},
				product: null,
				searchText: null,
			}
		},
		computed: {
			...mapState("OTHERS", ["aoNumberList", "districtList"]),
			...mapState("PREMIUMPOINT", ["addProductAOPackageSettingList"]),
			filteredData() {
				return this.searchText
					? filter(this.addProductAOPackageSettingList, (product) => {
						const premiumName = String(product.premiumName).toLowerCase()
						const premiumProductId = String(product.premiumProductId).toLowerCase()
						const searchText = String(this.searchText).toLowerCase()

						return premiumName.indexOf(searchText) > -1 || premiumProductId.indexOf(searchText) > -1
					})
					: this.addProductAOPackageSettingList
			},
		},
		watch: {
			aoNumberList: {
				deep: true,
				handler() {
					this.getAONumberOptions()
				},
				immediate: true,
			},
			districtList: {
				deep: true,
				handler() {
					this.getDistrictOptions()
				},
				immediate: true,
			},
			"localPackage.district": {
				handler() {
					this.resetProductAddAOPackageSetting()
				},
				immediate: true,
			},
		},
		created() {
			this.getDistrictList()
			this.getAONumberList()
		},
		beforeDestroy() {
			this.product = null
			this.searchText = null
			this.resetProductAddAOPackageSetting()
		},
		methods: {
			...mapActions({
				addProduct: "PREMIUMPOINT/addProductAddAOPackageSetting",
				createProductPriority: "PREMIUMPOINT/createProductPriority",
				deleteProductAddAOPackageSetting: "PREMIUMPOINT/deleteProductAddAOPackageSetting",
				getAONumberList: "OTHERS/getAONumberList",
				getAOPackageSettingList: "PREMIUMPOINT/getAOPackageSettingList",
				getAOPackageSettingProductDetail: "PREMIUMPOINT/getAOPackageSettingProductDetail",
				getDistrictList: "OTHERS/getDistrictList",
				resetProductAddAOPackageSetting: "PREMIUMPOINT/resetProductAddAOPackageSetting",
				updateProductAddAOPackageSetting: "PREMIUMPOINT/updateProductAddAOPackageSetting",
			}),
			closeAddModal() {
				this.is.showing = {
					...this.is.showing,
					addModal: false,
				}
			},
			closeDeleteDialog() {
				this.deleteProductId = null

				this.is.showing = {
					...this.is.showing,
					deleteDialog: false,
				}

				this.is = {
					...this.is,
					loading: false,
				}
			},
			closeUpdateModal() {
				this.is.showing = {
					...this.is.showing,
					updateModal: false,
				}

				this.is = {
					...this.is,
					loading: false,
				}
			},
			createProduct() {
				let promises = []

				each(this.addProductAOPackageSettingList, (agriInput) => {
					promises.push(
						this.createProductPriority({
							aoQuestionNumber: this.localPackage.aoNumber,
							districtId: this.localPackage.district,
							priority: agriInput.priority,
							productId: agriInput.premiumProductId,
						}),
					)
				})

				Promise.all(promises)
					.then(() => {
						this.$swal(
							`${this.$t("Success", "Success")}!`,
							this.$t("A new AO package setting has been created", "A new AO package setting has been created"),
							"success",
						)

						this.is = {
							...this.is,
							loading: false,
						}
						this.resetProductAddAOPackageSetting()

						this.$router.push({
							name: "Premium Points AO Package Setting List",
						})
					})
					.catch(() => {
						this.is = {
							...this.is,
							loading: false,
						}

						this.$swal(
							`${this.$t("Failed", "Failed")}!`,
							this.$t("Unable to save AO package setting", "Unable to save AO package setting"),
							"error",
						)
					})
			},
			deleteData() {
				this.is = {
					...this.is,
					loading: true,
				}

				this.deleteProductAddAOPackageSetting(this.deleteProductId).then(() => {
					this.closeDeleteDialog()
				})
			},
			getAONumberOptions() {
				this.aoNumberOptions = this.aoNumberList
			},
			getDistrictOptions() {
				this.districtOptions = map(this.districtList, (district) => {
					return {
						text: district.district,
						value: district.districtId,
					}
				})

				this.districtOptions = reject(this.districtOptions, (district) => {
					return !district.text || !district.value
				})
			},
			onSearch($event) {
				const self = this

				this.is = {
					...this.is,
					loading: true,
				}
				this.searchText = $event

				setTimeout(() => {
					self.is = {
						...self.is,
						loading: false,
					}
				}, 500)
			},
			save() {
				this.is = {
					...this.is,
					loading: true,
				}

				this.getAOPackageSettingList({
					limit: 10,
					offset: 0,
					order: null,
					search: null,
				}).then((aoPackageSettingList) => {
					const matchedAOPackageByDistrict = filter(
						aoPackageSettingList,
						(aoPackage) => String(aoPackage.districtCode) === String(this.localPackage.district),
					)

					if (matchedAOPackageByDistrict.length > 0) {
						const matchedAOPackageByAONumber = filter(
							matchedAOPackageByDistrict,
							(aoPackage) => String(aoPackage.aoQuestionNo) === String(this.localPackage.aoNumber),
						)

						if (matchedAOPackageByAONumber.length > 0) {
							this.unableToCreateProduct(
								`${this.$t("AO Number", "AO Number")} ${this.localPackage.aoNumber} ${this.$t(
									"already exist in district",
									"already exist in district",
								)} ${matchedAOPackageByDistrict[0].districtName}`,
							)
						} else {
							this.createProduct()
						}
					} else {
						this.createProduct()
					}
				})
			},
			showAddModal() {
				if (this.localPackage.district) {
					this.is.showing = {
						...this.is.showing,
						addModal: true,
					}
				} else {
					this.$swal(`${this.$t("Failed", "Failed")}!`, this.$t("District can't be empty", "District can't be empty"), "error")
				}
			},
			showDeleteDialog({ premiumProductId }) {
				this.deleteProductId = premiumProductId

				this.is.showing = {
					...this.is.showing,
					deleteDialog: true,
				}
			},
			showUpdateModal(product) {
				this.product = product

				this.is.showing = {
					...this.is.showing,
					updateModal: true,
				}
			},
			unableToCreateProduct(errorMessage) {
				this.is = {
					...this.is,
					loading: false,
				}

				this.$swal(`${this.$t("Failed", "Failed")}!`, errorMessage, "error")
			},
			updateProduct(product) {
				this.is = {
					...this.is,
					loading: true,
				}

				this.updateProductAddAOPackageSetting(product).then(() => {
					this.closeUpdateModal()
				})
			},
		},
	}
</script>
