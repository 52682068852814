<template>
	<div class="main-content">
		<basic-data-product-add-priority-modal :product="product" :show="is.showing.addModal" @close="closeAddModal" @on-save="save" />
		<ktv-modal v-model="is.showingModal" size="lg" no-close-on-backdrop no-close-on-esc :title="$t('Add Product', 'Add Product')" @close="close()">
			<template #content>
				<ktv-table
					id="add-product"
					:columns="columns"
					:filter="false"
					:is-loading="is.loading"
					:line-numbers="false"
					:rows="filteredData"
					:search-enabled="false"
					:search-on-enter="false"
					:search-placeholder="$t('Search by product name or id', 'Search by product name or id')"
					:total-rows="uniqueData ? filteredData.length : 0"
					min-height="250px"
					mode="client"
					@on-search="onSearch"
				>
					<template #columns="{ props }">
						{{ $t(props.column.label, props.column.label) }}
					</template>
					<template #rows="{ props }">
						<span v-if="props.column.field === 'button'">
							<ktv-button :loading="is.loading" :text="$t('Choose', 'Choose')" size="sm" @click="chooseProduct(props.row)" />
						</span>
						<span v-else-if="props.column.field === 'salePrice'">{{ props.row.salePrice | Rupiah }}</span>
					</template>
				</ktv-table>
			</template>
			<template #footer>
				<div align="right">
					<ktv-button :text="$t('Close', 'Close')" color="light" :loading="is.loading" icon="close" class="ml-2" @click="close()" />
				</div>
			</template>
		</ktv-modal>
	</div>
</template>
<script>
	import { filter, pluck } from "underscore"
	import { KtvButton, KtvModal, KtvTable } from "@/components"
	import { mapActions, mapGetters, mapState } from "vuex"

	import BasicDataProductAddPriorityModal from "./_BasicDataProductAddPriority"

	export default {
		name: "PremiumPointsAOPackageAddBasicDataAddProduct",
		metaInfo: {
			title: "Package Add - Basic Data - Add Product",
		},
		components: { BasicDataProductAddPriorityModal, KtvButton, KtvModal, KtvTable },
		props: {
			districtId: {
				default: 0,
				required: true,
				type: [Number, String],
			},
			show: {
				default: false,
				required: true,
				type: Boolean,
			},
		},
		emits: ["close", "on-save"],
		data() {
			return {
				columns: [
					{
						field: "premiumProductId",
						label: "ID",
						tdClass: "text-center",
						thClass: "text-center",
						width: "100px",
					},
					{
						field: "premiumName",
						label: "Product Name",
						thClass: "text-left",
					},
					{
						field: "productUnitName",
						label: "Unit Type",
						tdClass: "text-center",
						thClass: "text-center",
						width: "150px",
					},
					{
						field: "salePrice",
						label: "Price (/unit)",
						tdClass: "text-right",
						thClass: "text-right",
						width: "200px",
					},
					{
						field: "button",
						html: true,
						sortable: false,
						tdClass: "text-center",
						thClass: "text-center",
						width: "150px",
					},
				],
				is: {
					loading: false,
					showing: {
						addModal: false,
					},
					showingModal: false,
				},
				product: null,
				searchText: null,
			}
		},
		computed: {
			...mapGetters({
				entityOrganizationId: "AUTHDATA/userEntityOrganizationID",
			}),
			...mapState("PREMIUMPOINT", ["addProductAOPackageSettingList", "productList"]),
			existingIds() {
				return pluck(this.addProductAOPackageSettingList, "premiumProductId")
			},
			filteredData() {
				return this.searchText
					? filter(this.uniqueData, (product) => {
						const premiumName = String(product.premiumName).toLowerCase()
						const premiumProductId = String(product.premiumProductId).toLowerCase()
						const searchText = String(this.searchText).toLowerCase()

						return premiumName.indexOf(searchText) > -1 || premiumProductId.indexOf(searchText) > -1
					})
					: this.uniqueData
			},
			uniqueData() {
				return filter(this.productList, (product) => {
					return this.existingIds.indexOf(product.premiumProductId) === -1
				})
			},
		},
		watch: {
			show: {
				handler(show) {
					this.is = {
						...this.is,
						showingModal: show,
					}

					if (show) {
						this.is = {
							...this.is,
							loading: true,
						}

						this.getProductList({
							district_id: this.districtId,
							partner_entity_id: this.entityOrganizationId,
						})
							.then(() => {
								this.is = {
									...this.is,
									loading: false,
								}
							})
							.catch(() => {
								this.is = {
									...this.is,
									loading: false,
								}
							})
					}
				},
			},
		},
		beforeDestroy() {
			this.product = null
			this.searchText = null
		},
		methods: {
			...mapActions({
				getProductList: "PREMIUMPOINT/getProductList",
			}),
			chooseProduct(product) {
				this.product = product

				this.is.showing = {
					...this.is.showing,
					addModal: true,
				}
			},
			close() {
				this.is = {
					...this.is,
					loading: false,
				}

				this.searchText = null

				this.$emit("close")
			},
			closeAddModal() {
				this.is.showing = {
					...this.is.showing,
					addModal: false,
				}
			},
			onSearch($event) {
				const self = this

				this.is = {
					...this.is,
					loading: true,
				}
				this.searchText = $event

				setTimeout(() => {
					self.is = {
						...self.is,
						loading: false,
					}
				}, 500)
			},
			save(product) {
				this.is = {
					...this.is,
					loading: true,
				}

				this.$emit("on-save", product)
				this.$emit("close")
			},
		},
	}
</script>
